<template>
  <div class="content">

    <div class="container inside-container">
      <h1 class="b-title-sub">
        1-ый вебинар «Мигрень от А до Я» (с участием <br> международных и российских экспертов)
      </h1>
      <div class="cal-date">
        5 августа, 2020
      </div>

      <div class="video-slider">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="video-block">
                <img src="img/pic8.jpg" class="img-responsive">
                <span class="play-ico"></span>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="video-block">
                <img src="img/video.png" class="img-responsive">
                <span class="play-ico"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next next"></div>
        <div class="swiper-button-prev prev"></div>
      </div>

      <div class="content-text text-size-middle">
        <h3>
          «Раскрываем новые возможности профилактики мигрени»
        </h3>

        <p>Мигрень – это хроническое заболевание, приводящее к физической и психологической <br> нетрудоспособности, которое
          нарушает способность человека выполнять повседневную деятельность <br> и тем самым оказывает отрицательное
          влияние на качество жизни пациента.</p>

        <ul class="list-block">
          <li>
            <p>Бремя мигрени</p>
          </li>
          <li>
            <p>Диагностические критерии</p>
          </li>
          <li>
            <p>Различия клинических проявлений ЭМ и ХМ</p>
          </li>
          <li>
            <p>Различия ЭМ и ХМ на МРТ</p>
          </li>
          <li>
            <p>Прогрессирование ЭМ в ХМ</p>
          </li>
          <li>
            <p>Роль CGRP при мигрени</p>
          </li>
          <li>
            <p>Преимущества анти-CGRP МАТ для профилактики мигрень</p>
          </li>
        </ul>

        <br>

        <div class="speakers-block">
          <div class="event-row clearfix">
            <div class="event-name">Спикеры</div>
            <div class="event-desc">
              <div class="speaker">
                <div class="photo">
                  <img src="img/speaker3.jpg">
                </div>
                <div class="desc">
                  <div class="name">Заза Кацарава</div>
                  <div class="text">Врач-невролог первой категории</div>
                </div>
              </div>
              <div class="speaker">
                <div class="photo">
                  <img src="img/speaker4.jpg">
                </div>
                <div class="desc">
                  <div class="name">Табеева Гульнура</div>
                  <div class="text">Врач-невролог высшей категории · Кандидат медицинских наук</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="shedule-link">
          <div class="instructions">
            <a href="#" class="instruction-link">Полезный документ</a>
          </div>
        </div>

        <!-- <div class="webinar-controls">
          <a href="#" class="button button-arrow"><span>2-ая часть вебинара</span></a>
        </div> -->

        <h1 class="b-title-sub mid-title" data-aos="fade-right" data-aos-delay="0" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-mirror="true" data-aos-once="true">
          Смотрите так же
        </h1>

        <div class="event-slider-section">
          <div class="swiper-container event-slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <router-link to="/first-webinar" class="event-info-block">
                  <div class="pic" style="background: url(img/slide-pic1.png) center center no-repeat; background-size: cover;"></div>
                  <div class="desc type1">
                    <div class="theme">Конференция</div>
                    <div class="text">5-я ежегодная конференция «ВЕЙНОВСКИЕ ЧТЕНИЯ»</div>
                    <div class="date">28 февраля, 2019</div>
                  </div>
                </router-link>
              </div>
              <div class="swiper-slide">
                <router-link to="/first-webinar" class="event-info-block">
                  <div class="pic" style="background: url(img/slide-pic2.png) center center no-repeat; background-size: cover;"></div>
                  <div class="desc type2">
                    <div class="theme">Вебинары</div>
                    <div class="text">Серия вебинаров «Мигрень от А до Я», с участием международных и российских экспертов</div>
                    <div class="date">28 февраля, 2019</div>
                  </div>
                </router-link>
              </div>
              <div class="swiper-slide">
                <router-link to="/first-webinar" class="event-info-block">
                  <div class="pic" style="background: url(img/slide-pic3.png) center center no-repeat; background-size: cover;"></div>
                  <div class="desc type3">
                    <div class="theme">Лекции</div>
                    <div class="text">Международный междисциплинарный конгресс «MANAGE PAIN»</div>
                    <div class="date">28 февраля, 2019</div>
                  </div>
                </router-link>
              </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-button-next next"></div>
            <div class="swiper-button-prev prev"></div>

            <router-link to="/training" class="arrow-link">Все лекции</router-link>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import Swiper, { Navigation, Autoplay } from 'swiper'
Swiper.use([Navigation, Autoplay])

export default {
  name: 'FirstWebinar',
  title: 'Вебинар',
  mounted () {
    this.bannerSwiper()
  },
  methods: {
    bannerSwiper () {
      // eslint-disable-next-line no-new
      new Swiper('.video-slider .swiper-container', {
        slidesPerView: 1,

        autoplay: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })
      // eslint-disable-next-line no-new
      new Swiper('.event-slider', {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 4
          }
        }
      })
    }
  }
}
</script>
